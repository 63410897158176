import React, { useState, useEffect } from "react";
import { ColorPicker, Spin, Select, message, ConfigProvider } from "antd";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "../../Api/useApi.ts";
import { useDispatch } from "react-redux";
import { updateForm } from "../../Redux/slices/formSlice.tsx";
import { LoadingOutlined } from "@ant-design/icons";
import { Slider } from "antd";

const buttonSizes = [
  { value: "small", label: "Regular" },
  { value: "medium", label: "Medium" },
  { value: "large", label: "Large" },
];

const buttonTextStyles = [
  { value: "false", label: "Normal" },
  { value: "true", label: "Bold" },
];

const DesignTab = () => {
  const { theme, setTheme, form, setThemeChanged } = useFormEditorContext();
  const [messageApi, contextHolder] = message.useMessage();
  const [fontOptions, setFontOptions] = useState([]);
  const [textSize, setTextSize] = useState(theme.text_size || "small");
  const [buttonSize, setButtonSize] = useState(theme.button_size);
  const [fontFamily, setFontFamily] = useState(theme?.font_family);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonRadius, setButtonRadius] = useState(theme?.rounded_corners);
  const [buttonTextbold, setButtonTextbold] = useState(
    theme?.is_bold || "true"
  );
  const [colors, setColors] = useState({
    background_color: theme?.background_color,
    text_color: theme?.text_color,
    button_color: theme?.button_color,
    button_text_color: theme?.button_text_color,
    answer_text_color: theme?.answer_text_color,
  });

  // Update theme object in context when state changes
  useEffect(() => {
    setThemeChanged(true);
    setTheme((prevTheme) => ({
      ...prevTheme,
      text_size: textSize,
      button_size: buttonSize,
      rounded_corners: buttonRadius,
      font_family: fontFamily,
      is_bold: buttonTextbold,
      ...colors,
    }));
  }, [
    textSize,
    buttonSize,
    buttonRadius,
    colors,
    setTheme,
    fontFamily,
    buttonTextbold,
  ]);

  const handleColorChange = (colorType, colorObject) => {
    const { r, g, b } = colorObject.metaColor;
    const rgbString = `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(
      b
    )})`;
    setColors((prevColors) => ({
      ...prevColors,
      [colorType]: rgbString,
    }));
  };

  useEffect(() => {
    const fetchGoogleFonts = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_FONT_API_KEY}`
        );
        const data = await response.json();
        const googleFonts = data.items.map((font) => ({
          label: ` ${font.family}, ${font.category}`,
          value: `${font.family}, ${font.category}`,
          ...font,
        }));
        setFontOptions((prevOptions) => [...prevOptions, ...googleFonts]);
      } catch (error) {
        console.error("Error fetching Google Fonts:", error);
      }
    };

    fetchGoogleFonts();
  }, []);

  const dispatch = useDispatch();

  const handleButtonSize = (size) => {
    setButtonSize(size);
  };

  const handleButtonRadius = (size) => {
    setButtonRadius(size);
  };
  const handleTextSize = (size) => {
    setTextSize(size);
  };

  const handleButtonTextBold = (value) => {
    setButtonTextbold(value);
  };

  const handleFontChange = async (value) => {
    try {
      const selectedFont = fontOptions?.find((font) => font?.value === value);
      if (!selectedFont) {
        message.error("Selected font not found in options");
        return;
      }

      setFontFamily(selectedFont);
    } catch (error) {
      console.error("Error loading font:", error);
    }
  };

  const api = useApi();

  const updateMutation = useMutation({
    mutationFn: (theme_id) => api.themes.updateTheme(theme_id, theme),
    onSuccess: (data) => {
      const updatedTheme = data?.data;
      setTheme(updatedTheme);
      const updatedForm = { ...form, theme };
      dispatch(updateForm(updatedForm));
      //messageApi.success("Theme updated successfully");
      messageApi.open({
        type: "success",
        content: "Theme updated successfully",
        className: "custom-class",
        style: {
          marginLeft: "40vh",
        },
      });
      setIsLoading(false);
      setThemeChanged(false);
    },
    onError: (error) => {
      console.error("Error details:", error);
      messageApi.error("Error updating theme");
      setIsLoading(false);
    },
  });

  const handleThemeSave = () => {
    setIsLoading(true);
    updateMutation.mutate(theme?._id, theme);
  };

  return (
    <div className="h-full pb-8 overflow-x-hidden overflow-y-auto no-scrollbar ">
      {contextHolder}
      <div className="mt-6">
        <div className="pb-4 border-b border-zinc-200">
          <div className="flex items-center justify-between mb-4">
            <div>
              <div className="flex items-center gap-1 mb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  className="w-3 h-3 text-zinc-500"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 3a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v8.5a2.5 2.5 0 0 1-5 0V3Zm3.25 8.5a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                    clipRule="evenodd"
                  ></path>
                  <path d="m8.5 11.035 3.778-3.778a1 1 0 0 0 0-1.414l-2.122-2.121a1 1 0 0 0-1.414 0l-.242.242v7.07ZM7.656 14H13a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-.344l-5 5Z"></path>
                </svg>
                <h4 className="text-sm font-semibold">Colors</h4>
              </div>
              <p className="text-xs text-zinc-500">
                Background and text colors.
              </p>
            </div>
            <button
              type="button"
              aria-haspopup="dialog"
              aria-expanded="false"
              aria-controls="radix-:r2o:"
              data-state="closed"
              className="w-6 h-6 overflow-hidden rounded group"
            >
              <div className="grid w-12 h-12 grid-cols-2 overflow-hidden transition-all duration-300 ease-in-out -translate-x-1/4 -translate-y-1/4 rounded-xl">
                <span className="flex w-6 h-6 transition-all duration-300 ease-in-out group-hover:translate-x-full"></span>
                <span className="flex w-6 h-6 transition-all duration-300 ease-in-out group-hover:translate-y-full"></span>
                <span className="flex w-6 h-6 transition-all duration-300 ease-in-out group-hover:-translate-y-full"></span>
                <span className="flex w-6 h-6 transition-all duration-300 ease-in-out group-hover:-translate-x-full"></span>
              </div>
            </button>
          </div>
          <div className="flex flex-col w-full gap-2">
            <div className="flex items-center justify-between">
              <p className="text-xs font-medium text-zinc-500">
                Background Color
              </p>
              <div className="flex items-stretch gap-2 w-[120px]  border border-zinc-200 rounded-[9px] p-0.5 has-[:focus]:border-zinc-400 transition duration-150">
                <ColorPicker
                  defaultValue={colors.background_color}
                  className="w-full"
                  showText
                  onChange={(color) =>
                    handleColorChange("background_color", color)
                  }
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-xs font-medium text-zinc-500">Text Color</p>
              <div className="flex items-stretch gap-2 w-[120px]  border border-zinc-200 rounded-[9px] p-0.5 has-[:focus]:border-zinc-400 transition duration-150">
                <ColorPicker
                  defaultValue={colors.text_color}
                  className="w-full"
                  showText
                  onChange={(color) => handleColorChange("text_color", color)}
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-xs font-medium text-zinc-500">Button Color</p>
              <div className="flex items-stretch w-[120px]  gap-2 border border-zinc-200 rounded-[9px] p-0.5 has-[:focus]:border-zinc-400 transition duration-150">
                <ColorPicker
                  defaultValue={colors.button_color}
                  className="w-full"
                  showText
                  onChange={(color) => handleColorChange("button_color", color)}
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-xs font-medium text-zinc-500">
                Button Text Color
              </p>
              <div className="flex items-stretch w-[120px] gap-2 border border-zinc-200 rounded-[9px] p-0.5 has-[:focus]:border-zinc-400 transition duration-150">
                <ColorPicker
                  defaultValue={colors.button_text_color}
                  className="w-full"
                  showText
                  onChange={(color) =>
                    handleColorChange("button_text_color", color)
                  }
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-xs font-medium text-zinc-500">Answer Color</p>
              <div className="flex items-stretch w-[120px] gap-2 border border-zinc-200 rounded-[9px] p-0.5 has-[:focus]:border-zinc-400 transition duration-150">
                <ColorPicker
                  defaultValue={colors?.answer_text_color}
                  className="w-full"
                  showText
                  onChange={(color) =>
                    handleColorChange("answer_text_color", color)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 border-b border-zinc-200">
          <div className="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              aria-hidden="true"
              data-slot="icon"
              className="w-3 h-3 text-zinc-500"
            >
              <path
                fillRule="evenodd"
                d="M11 5a.75.75 0 0 1 .688.452l3.25 7.5a.75.75 0 1 1-1.376.596L12.89 12H9.109l-.67 1.548a.75.75 0 1 1-1.377-.596l3.25-7.5A.75.75 0 0 1 11 5Zm-1.24 5.5h2.48L11 7.636 9.76 10.5ZM5 1a.75.75 0 0 1 .75.75v1.261a25.27 25.27 0 0 1 2.598.211.75.75 0 1 1-.2 1.487c-.22-.03-.44-.056-.662-.08A12.939 12.939 0 0 1 5.92 8.058c.237.304.488.595.752.873a.75.75 0 0 1-1.086 1.035A13.075 13.075 0 0 1 5 9.307a13.068 13.068 0 0 1-2.841 2.546.75.75 0 0 1-.827-1.252A11.566 11.566 0 0 0 4.08 8.057a12.991 12.991 0 0 1-.554-.938.75.75 0 1 1 1.323-.707c.049.09.099.181.15.271.388-.68.708-1.405.952-2.164a23.941 23.941 0 0 0-4.1.19.75.75 0 0 1-.2-1.487c.853-.114 1.72-.185 2.598-.211V1.75A.75.75 0 0 1 5 1Z"
                clipRule="evenodd"
              ></path>
            </svg>
            <h4 className="text-sm font-semibold">Font</h4>
          </div>
          <p className="mb-4 text-xs text-zinc-500">
            Pick a font that reflects your brand
          </p>
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  activeBorderColor: "#000000",
                  activeOutlineColor: "#000000",
                  hoverBorderColor: "#000000",
                },
              },
            }}
          >
            <Select
              showSearch
              defaultValue={fontFamily}
              style={{
                width: "100%",
              }}
              className="border rounded-[9px] p-0.5 border-zinc-200"
              onChange={handleFontChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {fontOptions.map((font) => (
                <Option
                  key={font.value}
                  value={font.value}
                  style={{ fontFamily: font.value.split(",")[0] }}
                >
                  {font.label}
                </Option>
              ))}
            </Select>
          </ConfigProvider>
        </div>
        <div className="py-4 border-b border-zinc-200">
          <div className="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              aria-hidden="true"
              data-slot="icon"
              className="w-3 h-3 text-zinc-500"
            >
              <path
                fillRule="evenodd"
                d="M2.75 9a.75.75 0 0 1 .75.75v1.69l2.22-2.22a.75.75 0 0 1 1.06 1.06L4.56 12.5h1.69a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75v-3.5A.75.75 0 0 1 2.75 9ZM2.75 7a.75.75 0 0 0 .75-.75V4.56l2.22 2.22a.75.75 0 0 0 1.06-1.06L4.56 3.5h1.69a.75.75 0 0 0 0-1.5h-3.5a.75.75 0 0 0-.75.75v3.5c0 .414.336.75.75.75ZM13.25 9a.75.75 0 0 0-.75.75v1.69l-2.22-2.22a.75.75 0 1 0-1.06 1.06l2.22 2.22H9.75a.75.75 0 0 0 0 1.5h3.5a.75.75 0 0 0 .75-.75v-3.5a.75.75 0 0 0-.75-.75ZM13.25 7a.75.75 0 0 1-.75-.75V4.56l-2.22 2.22a.75.75 0 1 1-1.06-1.06l2.22-2.22H9.75a.75.75 0 0 1 0-1.5h3.5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-.75.75Z"
                clipRule="evenodd"
              ></path>
            </svg>
            <h4 className="text-sm font-semibold">Text Size</h4>
          </div>
          <p className="mb-4 text-xs text-start text-zinc-500">
            Change the size of the text
          </p>
          <div className="grid grid-cols-2 p-1 mt-4 rounded-lg bg-zinc-100">
            {buttonSizes
              .filter((button) => button.value !== "large")
              .map((button) => (
                <button
                  key={button.value}
                  className={`text-zinc-500 hover:text-zinc-950 border-transparent flex items-center justify-center py-1.5 font-medium rounded-lg ${
                    button.value === textSize ? "bg-white" : ""
                  }`}
                  onClick={() => handleTextSize(button.value)}
                >
                  <p className="text-xs capitalize">{button.label}</p>
                </button>
              ))}
          </div>
        </div>

        <div className="py-4 overflow-x-visible border-b border-zinc-200">
          <div className="flex items-center gap-1 mb-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              aria-hidden="true"
              data-slot="icon"
              className="w-3 h-3 text-zinc-500"
            >
              <path d="M3.75 2A1.75 1.75 0 0 0 2 3.75v1.5a.75.75 0 0 0 1.5 0v-1.5a.25.25 0 0 1 .25-.25h1.5a.75.75 0 0 0 0-1.5h-1.5ZM10.75 2a.75.75 0 0 0 0 1.5h1.5a.25.25 0 0 1 .25.25v1.5a.75.75 0 0 0 1.5 0v-1.5A1.75 1.75 0 0 0 12.25 2h-1.5ZM3.5 10.75a.75.75 0 0 0-1.5 0v1.5c0 .966.784 1.75 1.75 1.75h1.5a.75.75 0 0 0 0-1.5h-1.5a.25.25 0 0 1-.25-.25v-1.5ZM14 10.75a.75.75 0 0 0-1.5 0v1.5a.25.25 0 0 1-.25.25h-1.5a.75.75 0 0 0 0 1.5h1.5A1.75 1.75 0 0 0 14 12.25v-1.5ZM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
            </svg>
            <h4 className="text-sm font-semibold">Button Radius</h4>
          </div>
          <p className="text-xs text-start text-zinc-500">
            The corner radius of the button
          </p>
          <div className="relative w-full">
            <ConfigProvider
              theme={{
                components: {
                  Slider: {
                    dotBorderColor: "#000000",
                    handleColor: "#000000",
                    handleActiveColor: "#000000",
                    handleActiveOutlineColor: "#000000",
                    trackHoverBg: "#000000",
                    dotActiveBorderColor: "#000000",
                    dotSize: "0.5rem",
                    handleHoverColor: "#000000",
                  },
                },
              }}
            >
              <Slider
                styles={{
                  track: { backgroundColor: "black", color: "black" },
                  thumb: { backgroundColor: "black", color: "black" },
                  tracks: { backgroundColor: "black", color: "black" },
                  ring: { backgroundColor: "black", color: "black" },
                }}
                className="z-0 w-full slider-class"
                style={{
                  width: "100%",
                  left: "1.7%",
                }}
                onChange={handleButtonRadius}
                max={30}
                defaultValue={theme?.rounded_corners}
              />
            </ConfigProvider>
          </div>
        </div>
        <div className="py-4">
          <div className="flex items-center gap-1 mb-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              aria-hidden="true"
              data-slot="icon"
              className="w-3 h-3 text-zinc-500"
            >
              <path
                fillRule="evenodd"
                d="M2.75 9a.75.75 0 0 1 .75.75v1.69l2.22-2.22a.75.75 0 0 1 1.06 1.06L4.56 12.5h1.69a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75v-3.5A.75.75 0 0 1 2.75 9ZM2.75 7a.75.75 0 0 0 .75-.75V4.56l2.22 2.22a.75.75 0 0 0 1.06-1.06L4.56 3.5h1.69a.75.75 0 0 0 0-1.5h-3.5a.75.75 0 0 0-.75.75v3.5c0 .414.336.75.75.75ZM13.25 9a.75.75 0 0 0-.75.75v1.69l-2.22-2.22a.75.75 0 1 0-1.06 1.06l2.22 2.22H9.75a.75.75 0 0 0 0 1.5h3.5a.75.75 0 0 0 .75-.75v-3.5a.75.75 0 0 0-.75-.75ZM13.25 7a.75.75 0 0 1-.75-.75V4.56l-2.22 2.22a.75.75 0 1 1-1.06-1.06l2.22-2.22H9.75a.75.75 0 0 1 0-1.5h3.5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-.75.75Z"
                clipRule="evenodd"
              ></path>
            </svg>
            <h4 className="text-sm font-semibold">Button Size</h4>
          </div>
          <p className="text-xs text-start text-zinc-500">
            The size of the button
          </p>
          <div className="grid grid-cols-3 p-1 mt-4 rounded-lg bg-zinc-100">
            {buttonSizes.map((button) => (
              <button
                key={button?.value}
                className={`text-zinc-500 hover:text-zinc-950 border-transparent flex items-center justify-center py-1.5 font-medium rounded-lg ${
                  button.value === buttonSize ? "bg-white" : ""
                }`}
                onClick={() => handleButtonSize(button?.value)}
              >
                <p className="text-xs capitalize">{button.label}</p>
              </button>
            ))}
          </div>
        </div>
        <div className="py-4">
          <div className="flex items-center gap-1 mb-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              aria-hidden="true"
              data-slot="icon"
              className="w-3 h-3 text-zinc-500"
            >
              <path
                fillRule="evenodd"
                d="M2.75 9a.75.75 0 0 1 .75.75v1.69l2.22-2.22a.75.75 0 0 1 1.06 1.06L4.56 12.5h1.69a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75v-3.5A.75.75 0 0 1 2.75 9ZM2.75 7a.75.75 0 0 0 .75-.75V4.56l2.22 2.22a.75.75 0 0 0 1.06-1.06L4.56 3.5h1.69a.75.75 0 0 0 0-1.5h-3.5a.75.75 0 0 0-.75.75v3.5c0 .414.336.75.75.75ZM13.25 9a.75.75 0 0 0-.75.75v1.69l-2.22-2.22a.75.75 0 1 0-1.06 1.06l2.22 2.22H9.75a.75.75 0 0 0 0 1.5h3.5a.75.75 0 0 0 .75-.75v-3.5a.75.75 0 0 0-.75-.75ZM13.25 7a.75.75 0 0 1-.75-.75V4.56l-2.22 2.22a.75.75 0 1 1-1.06-1.06l2.22-2.22H9.75a.75.75 0 0 1 0-1.5h3.5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-.75.75Z"
                clipRule="evenodd"
              ></path>
            </svg>
            <h4 className="text-sm font-semibold">Button Text Style</h4>
          </div>
          <p className="text-xs text-start text-zinc-500">
            The style of the button text
          </p>
          <div className="grid grid-cols-2 p-1 mt-4 rounded-lg bg-zinc-100">
            {buttonTextStyles.map((button) => (
              <button
                key={button.value}
                className={`text-zinc-500 hover:text-zinc-950 border-transparent flex items-center justify-center py-1.5 font-medium rounded-lg ${
                  button.value === buttonTextbold ? "bg-white" : ""
                }`}
                onClick={() => handleButtonTextBold(button.value)}
              >
                <p className="text-xs capitalize">{button?.label}</p>
              </button>
            ))}
          </div>
        </div>
        <button
          className="whitespace-nowrap ring-offset-white focus-visible:outline-none focus-visible:ring-2  focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50    disabled:bg-zinc-700 bg-[#191B33]  transition duration-150 rounded-lg py-2.5 px-2 w-full text-white text-xs font-medium flex items-center justify-center h-9 gap-x-1.5"
          onClick={() => {
            handleThemeSave();
          }}
        >
          {!isLoading ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="w-3 h-3 mr-2"
              >
                <path d="M1 12.5A4.5 4.5 0 0 0 5.5 17H15a4 4 0 0 0 1.866-7.539 3.504 3.504 0 0 0-4.504-4.272A4.5 4.5 0 0 0 4.06 8.235 4.502 4.502 0 0 0 1 12.5Z"></path>
              </svg>
              <span>Save Theme</span>
            </>
          ) : (
            <Spin
              indicator={<LoadingOutlined spin />}
              spinning={isLoading}
              className="text-white "
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default DesignTab;
