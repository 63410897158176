import React from "react";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { SiderFieldCard } from "../FormEditor/SiderFieldCard";
import { Divider } from "antd";
import { Reorder } from "framer-motion";
import { AddFieldButton } from "../FormEditor/AddFieldButton";
import { FormDeleteButton } from "../FormEditor/FormEditorSider/FormDeleteButton";
import { FormSaveButton } from "../FormEditor/FormEditorSider/FormSaveButton";
import { sideFade } from "../../Constants/animations";

const ContentTab = ({ ai = false }) => {
  const { fields, setFields, isWelcomeIsEnabled } = useFormEditorContext();

  return (
    <>
      <div className="flex flex-col h-full pt-5 pb-4 overflow-y-auto ">
        <div className="flex flex-col items-start justify-start gap-1 mb-1 text-start">
          {/* steps */}
          <div className="w-full mt-4">
            <div className="flex items-center gap-1 mb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="w-3 h-3 text-zinc-500"
              >
                <path d="M2 4a2 2 0 0 1 2-2h8a2 2 0 1 1 0 4H4a2 2 0 0 1-2-2ZM2 9.25a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.25ZM2.75 12.5a.75.75 0 0 0 0 1.5h10.5a.75.75 0 0 0 0-1.5H2.75Z"></path>
              </svg>
              <h4 className="text-sm font-semibold">Steps</h4>
            </div>
            <p className="text-xs text-zinc-500 mb-3.5">
              The steps users will take to complete the form
            </p>
            <div className="flex flex-col w-full gap-2 overflow-y-auto min-h-max">
              {isWelcomeIsEnabled && (
                <SiderFieldCard
                  field={"welcome-screen"}
                  label={"Welcome screen"}
                />
              )}
              <Reorder.Group
                className="reorder-ul fill-available"
                axis="y"
                values={fields}
                onReorder={setFields}
              >
                {fields?.map((item, index) => (
                  <Reorder.Item
                    key={item?.id}
                    value={item}
                    {...sideFade}
                    className="reorder-li fill-available"
                  >
                    <SiderFieldCard field={item} index={index + 1} />
                  </Reorder.Item>
                ))}
              </Reorder.Group>
              <AddFieldButton ai={ai} />
              <Divider />
              <SiderFieldCard field={"end-screen"} label={"End screen"} />
            </div>
          </div>
        </div>

        <div className="flex gap-2 mt-auto">
          <FormSaveButton />
          <FormDeleteButton />
        </div>
      </div>
    </>
  );
};

export default ContentTab;
