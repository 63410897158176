import React from "react";
import { Modal, Tooltip, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  FIELD_TYPE_OPTIONS,
  QUESTION_ICONS,
  FIELD_TYPE_GROUPS,
} from "../../Constants/questionTypes";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { randomString } from "../../Utils/string";

export const AddFieldButton = ({ ai }) => {
  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");

  const {
    addField,
    setSelectedField,
    setWelcomeScreen,
    isWelcomeIsEnabled,
    setIsWelcomeIsEnabled,
  } = useFormEditorContext();

  const close = () => {
    setOpen(false);
  };
  function removeUnderscore(str) {
    if (str === "yes_no") return "Yes/No";
    return str.replace(/_/g, " ");
  }

  const filterFields = (group, key) => {
    const label = FIELD_TYPE_OPTIONS[key]?.label || removeUnderscore(key);
    return label.toLowerCase().includes(searchQuery.toLowerCase());
  };

  const getGroupBackgroundColor = (group) => {
    switch (group) {
      case "AI Features":
        return "bg-[#BAFFB7]";
      case "Choices":
        return "bg-[#E6CEF3]";
      case "Contact Info":
        return "bg-[#FFCACB]";
      case "Text and Video":
        return "bg-[#B5BDF4]";
      default:
        return "bg-[#a2c6e8]";
    }
  };

  const getDisabledGroupBackgroundColor = (group) => {
    switch (group) {
      case "AI Features":
        return "bg-[#A8E6BD]";
      case "Choices":
        return "bg-[#E6CEF3]";
      case "Contact Info":
        return "bg-[#F4D39F]";
      case "Preferences":
        return "bg-[#3d3d3e44]";
      case "Embeds":
        return "bg-[#3d3d3e44]";
      case "Text and Video":
        return "bg-[#B5BDF4]";
      default:
        return "bg-[#a2c6e8]";
    }
  };

  const addWelcomeScreen = () => {
    setWelcomeScreen({
      title: "Welcome to the form",
      description: "This is a description",
      button_text: "Start",
      layout: {
        type: "float",
        attachment: {
          type: "image",
          href: "",
        },
        placement: "right",
      },
    });
    setIsWelcomeIsEnabled(true);
    setSelectedField("welcome-screen");
    setOpen(false);
  };

  return (
    <>
      <button
        className="inline-flex items-center justify-center whitespace-nowrap font-medium ring-offset-white transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50   border border-zinc-200 bg-white hover:bg-zinc-100 hover:text-zinc-900 w-[90px]  rounded-lg text-xs h-7 px-2.5 gap-0.5"
        onClick={() => {
          setOpen(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          aria-hidden="true"
          data-slot="icon"
          class="h-3 w-3 -ml-0.5 mr-0.5"
        >
          <path d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z"></path>
        </svg>
        {ai ? "Add step" : "Add field"}
      </button>

      <Modal
        open={open}
        title={<span className="ml-2 "> Add Field</span>}
        onCancel={close}
        transitionName={""}
        footer={[]}
        width={800}
        closeIcon={<></>}
        style={{
          paddingTop: "2rem",
          minHeight: "70vh", // Set a maximum height for the modal
          overflowY: "auto", // Add vertical
          scrollBehavior: "smooth",
          scrollbarColor: "transparent transparent",
          scrollbarWidth: "thin",
          borderRadius: "1rem",
        }}
        centered
      >
        <div className="grid grid-cols-3 ">
          <div className="col-span-1 pr-4 ml-2">
            <Input
              placeholder="Search for a field"
              style={{
                padding: "0.2rem",
                justifyContent: "center",
              }}
              className="items-center w-3/4 p-2 my-2 bg-gray-100 border border-gray-200 rounded-lg"
              prefix={
                <SearchOutlined className="pl-2.5 pr-1 text-gray-500 "></SearchOutlined>
              }
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <h3 className="my-2 font-medium text-md">Recommended</h3>
            {FIELD_TYPE_GROUPS.Recommended.map((key) => (
              <button
                key={key}
                className="flex items-center border  w-3/4 border-gray-200 rounded-md gap-3 p-2 my-1 text-[12px] font-semibold text-start hover:bg-gray-100"
                onClick={() => {
                  setOpen(false);
                  const field = {
                    id: randomString(10),
                    ...FIELD_TYPE_OPTIONS[key],
                  };
                  addField(field);
                  setSelectedField(field);
                }}
              >
                <span
                  className={`text-[14px]  px-1.5 py-[2px] rounded-[0.4rem]  ${
                    key === "contact_info"
                      ? "bg-[#FFCACB]"
                      : key === "multiple_choice"
                      ? "bg-[#E6CEF3]"
                      : key === "dropdown"
                      ? "bg-[#E6CEF3]"
                      : key === "short_text"
                      ? "bg-[#B5BDF4]"
                      : "bg-[#E6CEF3]"
                  } `}
                >
                  {QUESTION_ICONS[key]}
                </span>
                <span className="text-[#5E5E5E]">
                  {FIELD_TYPE_OPTIONS[key].label}
                </span>
              </button>
            ))}
            <h3 className="my-2 font-medium text-md">Integration</h3>
            <button
              className="flex items-center border w-3/4 border-gray-200 text-[#bdbdbd] rounded-md gap-3 p-2 my-1 text-[12px] font-semibold text-start hover:cursor-not-allowed hover:bg-gray-100 "
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#FF7A59"
                  d="M0 6a6 6 0 0 1 6-6h12a6 6 0 0 1 6 6v12a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6z"
                ></path>
                <path
                  fill="#fff"
                  d="M16.001 9.439V7.653a1.38 1.38 0 0 0 .793-1.24v-.041c0-.76-.616-1.376-1.375-1.376h-.041a1.376 1.376 0 0 0-1.376 1.376v.04a1.38 1.38 0 0 0 .793 1.24V9.44a3.9 3.9 0 0 0-1.852.815L8.044 6.44q.052-.19.055-.386a1.55 1.55 0 1 0-1.551 1.546c.268 0 .531-.072.763-.207l4.824 3.754a3.91 3.91 0 0 0 .06 4.4l-1.468 1.468a1.3 1.3 0 0 0-.366-.06 1.273 1.273 0 1 0 1.273 1.274 1.3 1.3 0 0 0-.06-.366l1.452-1.452a3.914 3.914 0 1 0 2.975-6.971m-.602 5.875a2.009 2.009 0 1 1 0-4.015 2.008 2.008 0 0 1 .002 4.014"
                ></path>
              </svg>
              <span>HubSpot</span>
            </button>
            <button
              className="flex items-center border w-3/4 hover:cursor-not-allowed text-[#bdbdbd] border-gray-200 rounded-md gap-3 p-2 my-1 text-[12px] font-semibold text-start hover:bg-gray-100 "
              disabled
            >
              <img
                src="https://cdn.prod.website-files.com/5c06e16a5bdc7bce10059cc3/636fd0c6aa7ce42754d7b04e_J2IQV1CSzFQhv4zdtIXCPE22y7ihgGl7obTul7Sj8Jk.png"
                className="w-[24px] h-[24px] object-contain rounded-md"
                alt="Zapier"
              />
              <span>Zapier</span>
            </button>
            {!isWelcomeIsEnabled && (
              <button
                className="flex items-center border  w-3/4 border-gray-200 rounded-md gap-3 p-2 my-1 text-[12px] font-semibold text-start hover:bg-gray-100"
                onClick={() => {
                  addWelcomeScreen();
                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5c06e16a5bdc7bce10059cc3/636fd0c6aa7ce42754d7b04e_J2IQV1CSzFQhv4zdtIXCPE22y7ihgGl7obTul7Sj8Jk.png"
                  className="w-[24px] h-[24px] object-contain rounded-md"
                  alt="Zapier"
                />
                <span>Welcome Screen</span>
              </button>
            )}
          </div>
          <div className="grid w-full grid-cols-3 col-span-2 gap-y-4">
            {Object.keys(FIELD_TYPE_GROUPS)
              ?.filter((group) => group !== "Recommended")
              ?.map((group) => {
                const filteredFields = FIELD_TYPE_GROUPS[group].filter((key) =>
                  filterFields(group, key)
                );
                if (filteredFields.length === 0 && searchQuery) return null;
                return (
                  <div key={group}>
                    <h3 className="my-2 font-medium text-md">{group}</h3>
                    {filteredFields.map((key) =>
                      FIELD_TYPE_OPTIONS[key]?.label ? (
                        <button
                          key={key}
                          className="flex items-center gap-3 p-2 my-1 text-[12px] font-semibold text-start hover:bg-gray-100 rounded-lg w-full"
                          onClick={() => {
                            setOpen(false);
                            const field = {
                              id: Math.random().toString(36).substr(2, 10),
                              ...FIELD_TYPE_OPTIONS[key],
                            };
                            addField(field);
                            setSelectedField(field);
                          }}
                        >
                          <span
                            className={`text-[14px] px-1.5 py-[2px] rounded-[0.4rem] ${getGroupBackgroundColor(
                              group
                            )}`}
                          >
                            {QUESTION_ICONS[key]}
                          </span>
                          <span className="text-[#5E5E5E]">
                            {/* {FIELD_TYPE_OPTIONS[key].label} */}
                            {FIELD_TYPE_OPTIONS[key].label === "Section"
                              ? "Statement"
                              : FIELD_TYPE_OPTIONS[key].label}
                          </span>
                        </button>
                      ) : (
                        <Tooltip
                          key={key}
                          placement="top"
                          title={
                            <span className="text-xs break-normal whitespace-pre-line ">
                              Coming soon! You’ll be the first {"\n"} to know
                              (probably)
                            </span>
                          }
                        >
                          <button
                            className="flex items-center gap-3 p-2 text-[12px] hover:bg-gray-100 rounded-lg w-full font-semibold text-start"
                            disabled
                          >
                            <span
                              className={`text-[14px] px-1.5 py-[2px] rounded-[0.4rem] ${getDisabledGroupBackgroundColor(
                                group
                              )}`}
                            >
                              {QUESTION_ICONS[key]}
                            </span>
                            <span className="text-[#bdbdbd] capitalize">
                              {removeUnderscore(key)}
                            </span>
                          </button>
                        </Tooltip>
                      )
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
    </>
  );
};
