import React from "react";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { QUESTION_TYPES } from "../../Constants/questionTypes";
import { DeleteOutlined } from "@ant-design/icons";

export const SiderFieldCard = ({ field, index, label = null }) => {
  const {
    selectedField,
    setSelectedField,
    fields,
    setFields,
    isSidebarOpen,
    setIsSidebarOpen,
    setWelcomeScreen,
    setIsWelcomeIsEnabled,
    welcomeScreen,
  } = useFormEditorContext();

  const isSelected = selectedField === field;

  const removeField = (fieldId) => {
    const updatedFields = fields.filter((f) => f.id !== fieldId);
    setFields(updatedFields);
    setSelectedField(null);
  };

  const removeWelcomeScreen = () => {
    setWelcomeScreen({});
    setIsWelcomeIsEnabled(false);
    console.log(welcomeScreen);
    setSelectedField(null);
  };

  return (
    <div className="relative w-full group">
      <button
        className={`relative flex items-center w-full pl-0.5  transition duration-150 pr-6 rounded-lg cursor-pointer hover:bg-zinc-100 ${
          isSelected ? "bg-zinc-200" : "bg-zinc-50"
        }`}
        key={index}
      >
        <button className="pl-1 pr-1.5 h-8 ">
          {field === "welcome-screen" || field === "end-screen" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              data-slot="icon"
              className="text-zinc-400 h-2.5 w-2.5"
            >
              <path
                fill-rule="evenodd"
                d="M2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm5-2.25A.75.75 0 0 1 7.75 7h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1-.75-.75v-4.5Z"
                clip-rule="evenodd"
              ></path>
            </svg>
          ) : (
            <svg
              class="text-zinc-400 h-2.5 w-2.5"
              fill="currentColor"
              viewBox="0 0 8 13"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.5 0C0.671631 0 0 0.671539 0 1.5C0 2.32846 0.671631 3 1.5 3C2.32837 3 3 2.32846 3 1.5C3 0.671539 2.32837 0 1.5 0Z"></path>
              <path d="M6.5 0C5.67163 0 5 0.671539 5 1.5C5 2.32846 5.67163 3 6.5 3C7.32837 3 8 2.32846 8 1.5C8 0.671539 7.32837 0 6.5 0Z"></path>
              <path d="M1.5 5C0.671631 5 0 5.67154 0 6.5C0 7.32846 0.671631 8 1.5 8C2.32837 8 3 7.32846 3 6.5C3 5.67154 2.32837 5 1.5 5Z"></path>
              <path d="M6.5 5C5.67163 5 5 5.67154 5 6.5C5 7.32846 5.67163 8 6.5 8C7.32837 8 8 7.32846 8 6.5C8 5.67154 7.32837 5 6.5 5Z"></path>
              <path d="M1.5 10C0.671631 10 0 10.6715 0 11.5C0 12.3285 0.671631 13 1.5 13C2.32837 13 3 12.3285 3 11.5C3 10.6715 2.32837 10 1.5 10Z"></path>
              <path d="M6.5 10C5.67163 10 5 10.6715 5 11.5C5 12.3285 5.67163 13 6.5 13C7.32837 13 8 12.3285 8 11.5C8 10.6715 7.32837 10 6.5 10Z"></path>
            </svg>
          )}
        </button>

        <button
          //style={{ fontFamily: "Lexend Deca, san-serif" }}
          className="text-xs text-start px-1.5 font-medium w-full  focus:outline-none py-3"
          onClick={() => {
            isSelected ? setSelectedField(null) : setSelectedField(field);
          }}
        >
          {field?.type === QUESTION_TYPES.AI_STATEMENT
            ? "AI Statement"
            : field?.title
            ? field?.title
            : label || field?.label}
        </button>

        {field !== "end-screen" && (
          <button
            className=" max-w-[10px] max-h-[10px]"
            onClick={() => {
              setIsSidebarOpen(!isSidebarOpen);
              setSelectedField(field);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              data-slot="icon"
              className="w-[10px] h-[10px]  text-zinc-400 hover:text-zinc-900"
            >
              <path
                fill-rule="evenodd"
                d="M7.84 1.804A1 1 0 0 1 8.82 1h2.36a1 1 0 0 1 .98.804l.331 1.652a6.993 6.993 0 0 1 1.929 1.115l1.598-.54a1 1 0 0 1 1.186.447l1.18 2.044a1 1 0 0 1-.205 1.251l-1.267 1.113a7.047 7.047 0 0 1 0 2.228l1.267 1.113a1 1 0 0 1 .206 1.25l-1.18 2.045a1 1 0 0 1-1.187.447l-1.598-.54a6.993 6.993 0 0 1-1.929 1.115l-.33 1.652a1 1 0 0 1-.98.804H8.82a1 1 0 0 1-.98-.804l-.331-1.652a6.993 6.993 0 0 1-1.929-1.115l-1.598.54a1 1 0 0 1-1.186-.447l-1.18-2.044a1 1 0 0 1 .205-1.251l1.267-1.114a7.05 7.05 0 0 1 0-2.227L1.821 7.773a1 1 0 0 1-.206-1.25l1.18-2.045a1 1 0 0 1 1.187-.447l1.598.54A6.992 6.992 0 0 1 7.51 3.456l.33-1.652ZM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        )}
        {field !== "end-screen" && field !== "welcome-screen" && (
          <button
            className="absolute z-50 flex items-center justify-center w-5 h-5 p-1 transition-opacity duration-200 transform -translate-y-1/2 rounded-full opacity-0 right-[2px] text-zinc-800 top-1/2 group-hover:opacity-100 hover:opacity-100 "
            onClick={() => removeField(field?.id)}
          >
            {/*  <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              aria-hidden="true"
              data-slot="icon"
              className="w-4 h-4 text-zinc-400"
            >
              <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
            </svg> */}
            <DeleteOutlined className="text-red-400 "></DeleteOutlined>
          </button>
        )}
        {field === "welcome-screen" && (
          <button
            className="absolute z-50 flex items-center justify-center w-5 h-5 p-1 transition-opacity duration-200 transform -translate-y-1/2 rounded-full opacity-0 right-[2px] text-zinc-800 top-1/2 group-hover:opacity-100 hover:opacity-100 "
            onClick={() => removeWelcomeScreen()}
          >
            <DeleteOutlined className="w-2.5 h-2.5 text-red-400 "></DeleteOutlined>
          </button>
        )}
      </button>
    </div>
  );
};
