import tinycolor from "tinycolor2";

export const LAYOUT_VALUES = {
  HEADER_HEIGHT: "5vh",
  CONTENT_HEIGHT: "95vh",
  CONTENT_WIDTH: "70vw",
  RIGHT_SIDER_WIDTH: "14vw",
  LEFT_SIDER_WIDTH: "14vw",
  CONTENT_PADDING: "24px",
  CONTENT_BORDER_RADIUS: "20px",
  CONTENT_BORDER_RADIUS_MEDIUM: "10px",
};

export const siderLeftStyles = {
  height: LAYOUT_VALUES.CONTENT_HEIGHT,
  borderTopRightRadius: 20,
  borderBottomRightRadius: 20,
  overflow: "hidden",
};

export const siderRightStyles = {
  height: LAYOUT_VALUES.CONTENT_HEIGHT,
  borderTopLeftRadius: 20,
  borderBottomLeftRadius: 20,
  overflow: "hidden",
};

export const getQuestionIndexStyle = (color) => ({
  fontSize: "1em",
  lineHeight: "2.25em",
  color: color,
  marginBottom: "1em",
  // backgroundColor: "red",
});

export const indexIconMarginLeft = 5;

export const questionTitleStyle = (color) => ({
  fontSize: "24px",
  textAlign: "left",
  fontWeight: "bold",
  lineHeight: "1.25em",
  gap: 10,
});

export const subQuestionTitleStyle = (color) => ({
  marginTop: "1em",
  fontSize: "16px",
  textAlign: "left",
  color: color,
});

export const buttonTextSize = 20;

export const answerInputTextSize = 24;
export const answerCheckboxInputTextSize = 19;
export const answerCheckboxTextSize = 20;
export const selectOptionSize = 18;

export const questionDescriptionStyle = {
  fontSize: "1.4em",
  textAlign: "left",
  color: "rgba(30, 47, 105, 0.7)",
  marginBottom: "0.5em",
  marginTop: "0.2em",
  fontWeight: "normal",
};

export const formButtonProps = {
  initial: { opacity: 0, translateY: 10 },
  animate: { opacity: 1, translateY: 0 },
  style: {
    alignItems: "left",
    display: "flex",
    justifyContent: "flex-start",
  },
};

export const getInputStyle = (styles, isFocused) => {
  return {
    backgroundColor: "transparent", // Make the background transparent
    border: "none", // Remove the border
    boxShadow: "none", // Remove shadows if any
    borderBottom: `2px solid #ddd`, // Add a new border
    borderRadius: 0, // Remove border radius
    /*  borderColor: isFocused
      ? styles.secondary_color
      : tinycolor(styles.secondary_color)?.lighten(20).toString(),
    color: styles.secondary_color, */
    borderColor: isFocused
      ? styles?.answer_text_color
      : tinycolor(styles.answer_text_color)?.lighten(20).toString(),
    color: styles?.answer_text_color,
    paddingLeft: "0px", // Remove padding

  };
};

export const getSelectOptionBackgroundColor = (color, selected = false) => {
  return selected
    ? tinycolor(color).setAlpha(0.2).toRgbString()
    : tinycolor(color).setAlpha(0.1).toRgbString();
};

export const getSelectOptionBorderColor = (color) => {
  return tinycolor(color).lighten(10).toRgbString();
};

export const specialScreenTitleStyles = {
  fontSize: "2.5em",
  textAlign: "left",
  color: "rgb(30, 47, 105)",
  lineHeight: "1.25em",
  gap: 10,
  fontWeight: "bold",
};

export const specialScreenDescriptionStyles = {
  fontSize: "1.25em",
  textAlign: "left",
  // color: "rgba(30, 47, 105, 0.7)",
  marginBottom: "0.5em",
  marginTop: "0.5em",
};

export const getDynamicTextTitle = (constTheme) => {
  switch (constTheme?.text_size) {
    case "small":
      return "30px";
    case "medium":
      return "38px";
    case "large":
      return "px"; // Example value, adjust as needed
    default:
      return "28px"; // Default value if no match is found
  }
};

export const getDynamicTextDescription = (constTheme) => {
  switch (constTheme?.text_size) {
    case "small":
      return "1.25em";
    case "medium":
      return "1.75em";
    case "large":
      return "px"; // Example value, adjust as needed
    default:
      return "1em"; // Default value if no match is found
  }
};
