import React, { useState, useEffect, useRef } from "react";
import { Input } from "antd";
import { useFormEditorContext } from "../../../Contexts/FormEditorContext";
import {
  QuestionViewFormat,
  QuestionViewContentFormat,
} from "../../../Views/QuestionView.tsx";
import { Question, QuestionFormat } from "../../Form/Question";
import {
  questionTitleStyle,
  questionDescriptionStyle,
} from "../../../Constants/styles";
import AnswerSection from "../../Form/AnswerSection.tsx";
import { updateForm } from "../../../Redux/slices/formSlice.tsx";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { QUESTION_TYPES } from "../../../Constants/questionTypes.js";
import EditorAnswerSectionWrapper from "../EditorAnswerSection.tsx";

const SOCKET_SERVER_URL = process.env.REACT_APP_SERVICE_URL;
const SOCKET_PATH = "/statements";

export const QuestionEditor = () => {
  const {
    editorField,
    setEditorField,
    fields,
    form,
    theme,
    isPreview,
    setIsPreview,
    setFields,
  } = useFormEditorContext();
  const { TextArea } = Input;

  const updateField = (fieldId, key, subkey = null, value) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.map((field) => {
        if (field.id === fieldId) {
          if (subkey) {
            return {
              ...field,
              [key]: {
                ...field[key],
                [subkey]: value,
              },
            };
          } else {
            return {
              ...field,
              [key]: value,
            };
          }
        }
        return field;
      });
      return updatedFields;
    });

    setEditorField((prev) => {
      if (subkey) {
        return {
          ...prev,
          [key]: {
            ...prev[key],
            [subkey]: value,
          },
        };
      } else {
        return {
          ...prev,
          [key]: value,
        };
      }
    });

    //setChanged(true);
  };

  useEffect(() => {
    if (editorField) {
      const updatedField = fields.find((field) => field.id === editorField.id);
      if (updatedField) {
        setEditorField(updatedField);
      }
    }
  }, [fields]);

  if (!editorField) return null; // Render nothing if no field is selected

  const index = fields.findIndex((f) => f.id === editorField.id);

  if (editorField?.type === QUESTION_TYPES.AI_STATEMENT) {
    const socketRef = useRef(null);
    const [textArray, setTextArray] = useState([]);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    isCompleted ?? setIsPreview(false);

    useEffect(() => {
      socketRef.current = io(SOCKET_SERVER_URL, {
        path: SOCKET_PATH,
      });

      const socket = socketRef.current;

      socket.on("connect", () => {
        setIsConnected(true);
        setIsCompleted(false);
        setError(null);
        //   console.log("Connected to server");
      });

      socket.on("statement", (data) => {
        setIsLoading(false);
        setTextArray((prevArray) => [...prevArray, data?.statement]);
        setIsCompleted(data?.is_completed);
        if (data?.is_completed || !isConnected) {
          setIsPreview(false);
        }
      });

      socket.on("disconnect", () => {
        setIsConnected(false);
        setIsCompleted(false);
        //  console.log("Disconnected from server");
      });

      socket.on("connect_error", (err) => {
        setError("Connection error");
        console.error("Connection error: ", err);
      });

      socket.on("connect_timeout", () => {
        setError("Connection timeout");
        console.error("Connection timeout");
      });

      socket.on("error", (err) => {
        setError("An error occurred");
        console.error("Error: ", err);
      });

      return () => {
        socket.disconnect();
      };
    }, [editorField?.id]);

    const emitPreviewStatement = () => {
      setIsLoading(true);
      setTextArray([]);
      const updateFormLocal = { ...form, fields };
      dispatch(updateForm(updateFormLocal));
      // console.log("form to be send : ", updateFormLocal);
      socketRef.current.emit("preview_statement", {
        form: updateFormLocal,
        field_id: editorField?.id,
      });
    };
    useEffect(() => {
      if (isPreview) {
        emitPreviewStatement();
      }
    }, [isPreview, editorField?.id]);

    return (
      <QuestionViewFormat
        index={index}
        secondary_color={form?.primary_color || form?.secondary_color}
        content={
          <QuestionViewContentFormat
            question={
              <QuestionFormat
                aiStatement={true}
                isLoading={isLoading}
                textArray={textArray}
              />
            }
          />
        }
      />
    );
  }

  const [placeHolderColor, setPlaceHolderColor] = useState(null);

  const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, "");
    // Parse the hex string
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return { r, g, b };
  };

  // Utility function to convert RGB to hex
  const rgbToHex = (r, g, b) => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  };

  // New function to parse RGB string
  const parseRgb = (rgbString) => {
    const match = rgbString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    if (match) {
      return {
        r: parseInt(match[1]),
        g: parseInt(match[2]),
        b: parseInt(match[3]),
      };
    }
    return null;
  };

  const getBrightness = (r, g, b) => {
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  // Function to add opacity to a color
  const addOpacity = (color, opacity) => {
    const rgb = typeof color === "string" ? parseRgb(color) : color;
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
  };

  // Function to get the negative color
  const getNegativeColor = (
    color,
    opacityThreshold = 50,
    opacityValue = 0.5
  ) => {
    let rgb;

    if (typeof color === "string") {
      if (color.startsWith("#")) {
        rgb = hexToRgb(color);
      } else if (color.startsWith("rgb")) {
        rgb = parseRgb(color);
      }
    } else if (
      typeof color === "object" &&
      "r" in color &&
      "g" in color &&
      "b" in color
    ) {
      rgb = color;
    }

    if (!rgb) {
      console.error("Invalid color format:", color);
      return "rgba(0, 0, 0, 0.5)"; // Default to semi-transparent black if invalid input
    }

    // Invert each component
    const invertedR = 255 - rgb.r;
    const invertedG = 255 - rgb.g;
    const invertedB = 255 - rgb.b;

    // Calculate brightness of the inverted color
    const brightness = getBrightness(invertedR, invertedG, invertedB);

    // If the inverted color is too dark, add opacity
    if (brightness < opacityThreshold) {
      return addOpacity(
        { r: invertedR, g: invertedG, b: invertedB },
        opacityValue
      );
    }
    return rgbToHex(invertedR, invertedG, invertedB);
  };

  useEffect(() => {
    const negativeColor = getNegativeColor(theme?.background_color);
    setPlaceHolderColor(negativeColor);
  }, [theme?.background_color]);

  const isAttachment = editorField?.attachment?.link ? true : false;

  return (
    <QuestionViewFormat
      // index={index}
      secondary_color={form?.primary_color || form?.secondary_color}
      content={
        <QuestionViewContentFormat
          // bottomButton={<FormNextButton index={index}>Next</FormNextButton>}
          editor={true}
          isAttachment={isAttachment}
          question={
            <QuestionFormat
              title={
                <div>
                  <style>
                    {`
                    .dynamic-placeholder::placeholder {
                       color: ${placeHolderColor} !important;         
                    }
                  `}
                  </style>
                  <Input
                    style={{
                      ...questionTitleStyle(),
                      color: theme?.text_color,
                      paddingLeft: 0,
                      fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                    }}
                    className="dynamic-placeholder"
                    variant="borderless"
                    placeholder={"Add question title"}
                    value={editorField?.title || ""}
                    onChange={(e) =>
                      updateField(
                        editorField?.id,
                        "title",
                        null,
                        e.target.value
                      )
                    }
                  />
                </div>
              }
              description={
                <div>
                  <style>
                    {`
                  .dynamic-placeholder::placeholder {
                     color: ${placeHolderColor} !important;
                  }
                `}
                  </style>
                  <Input
                    variant="borderless"
                    placeholder="Add question description (optional)"
                    className="dynamic-placeholder"
                    value={editorField?.properties?.description || ""}
                    onChange={(e) =>
                      updateField(
                        editorField?.id,
                        "properties",
                        "description",
                        e.target.value
                      )
                    }
                    style={{
                      ...questionDescriptionStyle,
                      color: theme?.text_color,
                      paddingLeft: 0,
                      fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                    }}
                  />
                </div>
              }
              attachment={editorField?.attachment?.link}
              placement={editorField?.attachment?.placement}
              answer={
                editorField?.properties?.fields?.length > 0 ? (
                  editorField?.properties?.fields?.map((field, index) => (
                    <Question
                      key={field?.id}
                      field={field}
                      isLastQuestion={false}
                      editor={true}
                    />
                  ))
                ) : editorField?.type === QUESTION_TYPES.MULTIPLE_CHOICE ? (
                  <EditorAnswerSectionWrapper />
                ) : (
                  <AnswerSection
                    field={editorField}
                    editor={true}
                    isLastQuestion={false}
                  />
                )
              }
            />
          }
        />
      }
    />
  );
};
