import React, { useMemo, useState } from "react";

import { useParams, useSearchParams } from "react-router-dom";

import { keepPreviousData, useQuery } from "@tanstack/react-query";

// Components
import { DataTable } from "../Components/ResponsesTable/DataTable.tsx";
import { Checkbox } from "../Components/ui/checkbox.tsx";
import Header from "../Components/ResponsesTable/Header.tsx";
import Cell from "../Components/ResponsesTable/Cell.tsx";

import { useApi } from "../Api/useApi.ts";
import { DEFAULT_PAGINATION } from "../Api/usePagination.ts";
// import { useInfiniteQuery } from "react-query";

const CheckboxHeader = {
  id: "select",
  header: ({ table }) => (
    <Checkbox
      checked={
        table.getIsAllPageRowsSelected() ||
        (table.getIsSomePageRowsSelected() && "indeterminate")
      }
      onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
      aria-label="Select all"
    />
  ),
  cell: ({ row }) => (
    <Checkbox
      checked={row.getIsSelected()}
      onCheckedChange={(value) => row.toggleSelected(!!value)}
      aria-label="Select row"
    />
  ),
  width: 10,
  enableSorting: false,
  enableHiding: false,
};

function FormResponsesView() {
  const api = useApi();
  const { urlId } = useParams();
  const [searchParams] = useSearchParams();

  const query = searchParams.get("query");
  const from = searchParams.get("from");
  const to = searchParams.get("to");

  const { data: form, isFetched: isFormFetched } = useQuery({
    queryKey: ["form", urlId],
    queryFn: () => api.forms.fetchFormData(urlId),
    staleTime: Infinity,
  });

  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGINATION.page,
    size: DEFAULT_PAGINATION.size,
  });

  const {
    isFetched: isFormResponsesFetched,
    data: formResponses,
    isSuccess,
    isError,
  } = useQuery({
    queryKey: [
      "formResponses",
      query,
      from,
      to,
      pagination.page,
      pagination.size,
    ],
    queryFn: () =>
      api.forms.fetchFormResponses(urlId, pagination, {
        query,
        date: { from, to },
      }),
    staleTime: 1000,
    placeholderData: keepPreviousData,
  });

  console.log("pagination", pagination);

  // console.log("Form", formResponses);
  // console.log("Form Responses", formResponses?.data);

  //  const {data} = useInfiniteQuery({
  //     queryKey: ['form', 'responses'],
  //     queryFn: fetchProjects,
  //     initialPageParam: 0,
  //     getNextPageParam: (lastPage, allPages, lastPageParam) => {
  //       if (lastPage.length === 0) {
  //         return undefined
  //       }
  //       return lastPageParam + 1
  //     },
  //     getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
  //       if (firstPageParam <= 1) {
  //         return undefined
  //       }
  //       return firstPageParam - 1
  //     },
  //   })

  // const {
  //   fetchNextPage,
  //   fetchPreviousPage,
  //   hasNextPage,
  //   hasPreviousPage,
  //   isFetchingNextPage,
  //   isFetchingPreviousPage,
  //   promise,
  //   ...result
  // } = useInfiniteQuery({
  //   queryKey: ["formResponses"],
  //   queryFn: () => api.forms.fetchFormResponses(urlId),
  //   initialPageParam: 1,
  //   ...options,
  //   getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) =>
  //     lastPage.nextCursor,
  //   getPreviousPageParam: (
  //     firstPage,
  //     allPages,
  //     firstPageParam,
  //     allPageParams
  //   ) => firstPage.prevCursor,
  // });

  // TODO: Use React observer library to implement infinite scrolling
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       if (entries[0].isIntersecting) {
  //         loadMoreReplies();
  //       }
  //     },
  //     { threshold: 1.0 }
  //   );

  //   if (loadMoreTrigger.current) {
  //     observer.observe(loadMoreTrigger.current);
  //   }

  //   return () => {
  //     if (loadMoreTrigger.current) {
  //       observer.unobserve(loadMoreTrigger.current);
  //     }
  //   };
  // }, [currentPage, isFetching, displayedReplies]);

  const getColumns = () => {
    if (!isFormFetched)
      return Array.from({ length: 5 }, (_, i) => ({
        id: i.toString(),
      }));

    const fields = [
      ...form?.data?.fields?.filter((field) => field.type !== "ai_statement"),
      { title: "Status", type: "status" },
      { title: "Tags", type: "tags" },
      { title: "Date", type: "date" },
    ];
    const columns = fields?.map((field) => {
      return {
        accessorKey: field.title,
        header: ({ column }) => {
          return (
            <Header
              title={field.title}
              type={field.type}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            />
          );
        },
        cell: ({ row }) => (
          <Cell
            data={row.getValue(field.title)}
            type={field.type}
            onClick={() => {}}
          />
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
        width: 100,
      };
    });
    columns.unshift(CheckboxHeader);
    return columns;
  };

  const columns = useMemo(() => getColumns(), [form?.data?.fields]);

  const getData = () => {
    if (!isFormResponsesFetched) return [];

    const formFields = form?.data.fields;
    const out = [];
    for (const response of formResponses?.data.responses) {
      const questions = response.data;
      const entry = {
        id: response.response_id,
        Date: response.updated_at,
        Status: response.type,
      };
      for (const field of formFields) {
        const question = questions.find(
          (question) => question.question.question_id === field.id
        );
        entry[field.title] = question?.answer[0]?.answer_text ?? "";
      }
      out.push(entry);
    }
    return out;
  };

  const isLoaded = isFormFetched && isFormResponsesFetched;

  return (
    <DataTable
      columns={columns}
      total={formResponses?.data?.total ?? 0}
      data={getData()}
      isLoaded={isLoaded}
      onPaginationChange={(pagination) =>
        setPagination({
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
        })
      }
      onSortingChange={(sorting) => {}}
    />
  );
}

export default FormResponsesView;
