import React, { useRef, useEffect, useCallback } from "react";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { getLetterForIndex } from "../../Utils/string";
import { Col, Input, Row, Typography, Flex, Checkbox } from "antd";
import { motion } from "framer-motion";
import { QUESTION_TYPES } from "../../Constants/questionTypes";
import { getInputStyle } from "../../Constants/styles";
import { PhoneNumberInput } from "../Basic/PhoneNumberInput";
import {
  getSelectOptionBorderColor,
  getSelectOptionBackgroundColor,
} from "../../Constants/styles";
import { v4 as uuidv4 } from "uuid";
import { CloseOutlined } from "@ant-design/icons";
import tinycolor from "tinycolor2";

const EditorAnswerSection = () => {
  const { editorField, theme } = useFormEditorContext();

  const commonProps = {
    noStyle: true,
    validateTrigger: ["onSubmit"],
    style: {
      width: "100%",
      backgroundColor: "transparent",
    },
  };

  const newTheme = {
    secondaryColor: theme?.text_color,
  };

  const inputStyle = getInputStyle(newTheme);

  switch (editorField?.type) {
    case QUESTION_TYPES.MULTIPLE_CHOICE:
      return <CheckBoxEditor editorField={editorField} />;
  }
};

const EditorAnswerSectionWrapper = ({ index }) => {
  return (
    <motion.div
      key={"fjkds af"}
      initial={"false"}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ delay: 0, duration: 0 }}
      style={{
        minWidth: "100%",
        alignItems: "center",
        //paddingLeft: 10,
        display: "flex",
      }}
    >
      <EditorAnswerSection />
    </motion.div>
  );
};

export default EditorAnswerSectionWrapper;

const CheckBoxEditor = () => {
  const { theme, editorField, setEditorField, fields, setFields, setChanged } =
    useFormEditorContext();
  const options = editorField?.properties?.choices?.map((o) => ({
    ...o,
    value: o?.label,
  }));

  const span = options?.filter((o) => o.value.length > 24).length > 0 ? 24 : 8;

  const addOption = () => {
    const newOption = {
      id: uuidv4(),
      label: "",
      value: "",
    };
    setEditorField((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        choices: [...(prev.properties.choices || []), newOption],
      },
    }));
  };

  const inputRefs = useRef({});
  const saveTimeoutRef = useRef(null);

  const handleOption = useCallback((id, value) => {
    setEditorField((prev) => {
      const updatedField = {
        ...prev,
        properties: {
          ...prev.properties,
          choices: (prev.properties.choices || []).map((choice) =>
            choice.id === id
              ? { ...choice, label: value, value: value }
              : choice
          ),
        },
      };

      scheduleSave(updatedField);
      return updatedField;
    });

    // Maintain focus after state update
    setTimeout(() => {
      if (inputRefs.current[id]) {
        inputRefs.current[id].focus();
      }
    }, 0);
  }, []);

  const removeOption = useCallback((id) => {
    setEditorField((prev) => {
      const updatedField = {
        ...prev,
        properties: {
          ...prev.properties,
          choices: (prev.properties.choices || []).filter(
            (choice) => choice.id !== id
          ),
        },
      };

      scheduleSave(updatedField);
      return updatedField;
    });
  }, []);

  const scheduleSave = (updatedField) => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(() => {
      saveChanges(updatedField);
    }, 5000); // Debounce save for 500ms
  };

  const saveChanges = useCallback(
    (updatedEditorField) => {
      setChanged(true);
      const newFields = [...fields];
      const index = newFields.findIndex((f) => f?.id === updatedEditorField.id);

      const cleanEditorField = {
        ...updatedEditorField,
        title: updatedEditorField?.title || "",
        properties: {
          ...updatedEditorField.properties,
          description: updatedEditorField.properties?.description || "",
        },
      };

      newFields[index] = Object.fromEntries(
        Object.entries(cleanEditorField).filter(([key, value]) => value != null)
      );

      setFields(newFields);
    },
    [fields, setFields, setChanged]
  );

  // Cleanup function to clear any pending timeouts
  useEffect(() => {
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, []);

  const Item = ({ children }) => (
    <Col
      span={span}
      md={span}
      sm={24}
      xs={24}
      style={{
        alignItems: "start",
        display: "flex",
        // padding: 3,
        // backgroundColor: 'red',
        justifyContent: "flex-start",
      }}
    >
      {children}
    </Col>
  );

  return (
    <div className="flex flex-col gap-3 ">
      <Checkbox.Group style={{ width: "100%", border: "none" }}>
        <Row gutter={[8, 8]}>
          {editorField?.properties?.choices?.map((option, index) => (
            <Item>
              <Flex
                key={"other"}
                style={{
                  border: `1px solid #fff`,
                  borderWidth: 1,
                  borderColor: getSelectOptionBorderColor(
                    theme?.answer_text_color || theme?.button_color
                  ),
                  borderRadius: 4,
                  // borderColor: "red",
                  padding: "2px",
                  position: "relative",
                  /*  paddingTop: "4px",
                  paddingBottom: "4px", */
                  paddingLeft: "6px",
                  minWidth: "88px",
                  width: "100%",
                  // height: "100%",
                  alignItems: "center",
                  backgroundColor: getSelectOptionBackgroundColor(
                    theme?.answer_text_color || theme?.button_color
                  ),
                  // backgroundColor: 'red'
                }}
                gap={"small"}
              >
              <button
                  className="absolute text-white bg-[#57585B] z-10 top-1.5 -right-2 rounded-full p-1 opacity-0 hover:opacity-100 border border-white transition-opacity duration-200"
                  style={{ width: "16px", height: "16px" }}
                  onClick={() => removeOption(option.id)}
                >
                  <CloseOutlined
                    style={{ fontSize: "8px" }}
                    className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                  />
                </button>

                <Flex
                  style={{
                    border: `1px solid #fff`,
                    borderWidth: 1,
                    backgroundColor: "white",
                    borderColor: getSelectOptionBorderColor(
                      theme?.answer_text_color || theme?.button_color
                    ),

                    borderRadius: 2,

                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "left",
                    minWidth: "16px",
                    height: "16px",
                  }}
                >
                  <Typography.Text
                    style={{
                      color: theme?.answer_text_color,

                      fontWeight: "semibold",
                      fontSize: "12px",
                      padding: "1px",
                    
                     

                    }}
                  >
                    {getLetterForIndex(index)}
                  </Typography.Text>
                </Flex>
                <Input
                  key={option?.id}
                  ref={(el) => (inputRefs.current[option.id] = el)}
                  placeholder="choice"
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    border: "none",
                    outline: "none", // Remove the focus ring
                    boxShadow: "none", // Remove the focus ring
                    color: theme?.answer_text_color || theme?.text_color,
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  value={option?.label}
                  onChange={(e) => handleOption(option?.id, e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      addOption();
                    }
                  }}
                />
              </Flex>
            </Item>
          ))}
        </Row>
      </Checkbox.Group>
      <div className="w-full ">
        <button
          style={{
            color: theme?.answer_text_color,
          }}
          onClick={addOption}
          className="flex justify-start text-sm font-medium underline "
        >
          Add Choice
        </button>
      </div>
    </div>
  );
};
