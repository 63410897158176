import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { questionAnimation } from "../../Constants/animations";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import WelcomeScreenEditor from "../../Components/FormEditor/Fields/WelcomeScreenEditor";
import { EndScreenEditor } from "../FormEditor/Fields/EndScreenEditor";
import { QuestionEditor } from "../FormEditor/Fields/QuestionEditor";
import { FormHeaderFormat } from "../../Components/Form/FormHeader";
import { FormFooterFormat } from "../../Components/Form/FormFooter";

const ContentMainSection = () => {
  const { selectedField, fields, theme } = useFormEditorContext();
  const index = fields.findIndex((field) => field?.id === selectedField?.id);

  const dynamicHight =
    selectedField === "welcome-screen" ||
    selectedField === "end-screen" ||
    selectedField === null
      ? "h-screen-95"
      : "h-screen-90";

  return (
    <div className={`flex flex-col ${dynamicHight}`}>
      <div>
        {selectedField != "welcome-screen" &&
          selectedField != "end-screen" &&
          selectedField != null &&
          selectedField && (
            <FormHeaderFormat
              showPercentage={selectedField != "welcome-screen"}
              percentage={((index + 1) / fields.length) * 100}
              strokeColor={theme?.button_color || "rgb(208, 252, 3)"}
            />
          )}
      </div>

      <AnimatePresence mode="wait">
        <div className="flex items-center justify-center w-full h-full ">
          {selectedField == "welcome-screen" ? (
            <motion.div key="welcome-screen" {...questionAnimation}>
              <WelcomeScreenEditor />
            </motion.div>
          ) : selectedField == "end-screen" ? (
            <motion.div
              key="end-screen"
              {...questionAnimation}
              className="w-full "
            >
              <EndScreenEditor />
            </motion.div>
          ) : (
            <motion.div key={selectedField?.id} {...questionAnimation}>
              <QuestionEditor selectedField={selectedField} />
            </motion.div>
          )}
        </div>
      </AnimatePresence>
      <div className="fixed flex items-end justify-end w-1/2 bottom-3 right-3">
        {selectedField != "welcome-screen" &&
          selectedField != "end-screen" &&
          selectedField != null &&
          selectedField && (
            <FormFooterFormat
              showControls={selectedField != "welcome-screen"}
              theme={theme}
            />
          )}
      </div>
    </div>
  );
};

export default ContentMainSection;
